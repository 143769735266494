<template>
    <div class="page-qa-info">
        <div class="page-body">
            <div class="info-item header">
                <span>控制盒编号</span>
                <span>{{ $route.query.uniqueId }}</span>
            </div>
        </div>

        <div class="page-body" v-for="(item, index) in qaList" :key="index">
            <div class="info-item header" v-if="item.type == 2">{{index + 1 }}、功能质检</div>
            <div class="info-item header" v-if="item.type == 3">{{index + 1 }}、称重质检</div>
            <div class="info-item">
                <span>操作人</span>
                <span>{{ item.operatorName }}</span>
            </div>
            <div class="info-item">
                <span>质检提交时间</span>
                <span>{{ item.submitAt }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
export default {
    name: 'TlQaInfo',
    data() {
        return {
            qaList: []
        }
    },

    created() {
        this.loadQaInfo();
    },

    methods: {
        // 获取设备检测状态
        loadQaInfo() {
            const vm = this
            const tt = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}tl/qcList?uniqueId=${vm.$route.query.uniqueId}`,
            }).then(res => {
                let { data } = res.data || [];
                console.log(res)
                tt.clear();
                data.length && data.map(item => {
                    const s = item.submitAt.split('T')[1].split(':')
                    item.submitAt = `${item.submitAt.split('T')[0]} ${s[0]}:${s[1]}`
                })
                vm.qaList = data.filter(item => item.type > 1);
                console.log(vm.qaList)
            }).catch(() => {
                tt.clear();
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/less/base.less";
.page-qa-info {
    width: 100%;
    height: 100%;
    background: @bgColor2;
    padding: 20px 10px;
    .page-body {
        background: @blank;
        padding: 10px;
        border-radius: 10px;
        line-height: 2;
        margin-bottom: 10px;
        .info-item {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            font-size: 0.14rem;
            &.header {
                font-weight: bold;
            }
        }
    }
}
</style>
